import React from 'react';
import {Link} from 'gatsby';
import {Jumbotron, Container, Row} from 'react-bootstrap';
// import '../styles/hero.css'
import '../styles/heroinner.css'
import 'animate.css'

const BlogCrumbs = ({title: Title}) =>  {
        return (
            <Jumbotron className="hero-inner pl-4 pr-4" fluid>
                <Container fluid>
                    <Row className="align-items-center justify-content-center">
                        <h1 className="text-white">{Title}</h1>
                    </Row>
                    <Row className="align-items-center justify-content-center">
                        <p className="breadcrumb">
                            <Link to="/" className="text-white">home</Link> &nbsp; / &nbsp;<Link to="/blog" className="text-white">Blog</Link> &nbsp; / &nbsp; {Title}
                        </p>
                    </Row>
                </Container>
            </Jumbotron>
        );
}

export default BlogCrumbs;