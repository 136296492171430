import React from "react"
import { graphql } from "gatsby"
import Layout from '../components/layout'
import BlogCrumbs from "../components/blogcrumbs"
import Img from "gatsby-image"
import { Row, Container } from "react-bootstrap"
import SEO from "../components/seo"
import Share from "../components/share"

const BlogPost = ({ data }) => {
  console.log("data,", data)
  return (
    <Layout>
      <SEO title={data.markdownRemark.frontmatter.title} description={data.markdownRemark.frontmatter.description}/>
      <BlogCrumbs title={data.markdownRemark.frontmatter.title}/>
      <Container className="px-5 py-5">
        <Row className="justify-content-center mt-5">
          <h2>{data.markdownRemark.frontmatter.title}</h2>
        </Row>
        <Row className="justify-content-center">
          <Img
            className="mb-5 blog-img"
            style={{opacity: "1", width: "100%"}}
            fluid={data.markdownRemark.frontmatter.image.childImageSharp.fluid}
          />
        </Row>
          <Row className="justify-content-center">
            <div dangerouslySetInnerHTML={{__html: data.markdownRemark.html}}/>
          </Row>
          <Row className="justify-content-center align-items-center">
            <span className="mr-4">Share: </span>
            <Share
              socialConfig={{
                twitterHandle: `${data.site.siteMetadata.twitterHandle}`,
                config: {
                  url: `${data.site.siteMetadata.siteUrl}${data.markdownRemark.fields.slug}`,
                  title: `${data.markdownRemark.frontmatter.title}`,
                },
              }}
            />
          </Row>
        </Container>
    </Layout>
  )
}

export default BlogPost

export const BlogPostTemplateQuery = graphql`
  query BlogPostTemplateQuery($slug: String) {
    site {
      siteMetadata {
        siteUrl
        twitterHandle
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        description
        image {
          childImageSharp {
            fluid (maxWidth: 1500){
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`